import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='services-slides projects-slides'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/149.jpeg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/150.jpeg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/151.jpeg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/152.jpeg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/153.jpeg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/154.jpeg"
          alt="Second slide"
        />
        
      </Carousel.Item>

      
    </Carousel>
  );
}

export default PowerCarousel;