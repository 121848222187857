import Carousel from 'react-bootstrap/Carousel';

function RoadCarousel() {
  return (
    <Carousel indicators={false} className='services-slides projects-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/8.jpg"
          alt="First slide"
        />

      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/12.jpg"
          alt="First slide"
        />
      </Carousel.Item>

     <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/28.jpg"
          alt="First slide"
        />
      </Carousel.Item>


      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/35.jpg"
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/36.jpg"
          alt="First slide"
        />
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/37.jpg"
          alt="First slide"
        />
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/38.jpg"
          alt="First slide"
        />
      </Carousel.Item> 
  
    </Carousel>
  );
}

export default RoadCarousel;