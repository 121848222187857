import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='services-slides projects-slides'>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/185.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/186.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/187.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/188.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/189.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/190.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/191.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/192.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/193.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/194.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/195.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/196.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/197.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/198.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/199.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/200.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/201.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/202.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/203.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/204.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/205.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/206.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/207.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/208.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/209.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/210.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/211.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/212.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/213.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/214.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/215.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/216.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/217.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/218.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/219.jpg"
          alt="Second slide"
        />     
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/220.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>



      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/155.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/156.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/157.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/158.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/159.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/160.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/161.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
    
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/162.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/163.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/164.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/165.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/166.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/167.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/168.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/169.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/170.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/171.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/172.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/173.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/174.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/175.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/176.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/177.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/178.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/179.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/180.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/181.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/182.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/183.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/184.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>

      
    </Carousel>
  );
}

export default PowerCarousel;